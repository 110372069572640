import http from './http';
import qs from 'qs';
export function adminLogin(data) {
  return http.post('/admin/login', data);
}
export function getAdminUserInfo() {
  return http.get('/admin/userInfo');
}
export function getOpenServerNotices(page = 1, size = 10) {
  return http.get(`/open_server_notice?page=${page}&size=${size}`);
}
export function createOpenServerNotice(data) {
  return http.post('/open_server_notice', data);
}
export function updateOpenServerNotice(id, data) {
  return http.post(`/open_server_notice?id=${id}`, data);
}
export function getHeroInfo(id, part) {
  return http.get(`/hero?id=${id}&part=${part}`);
}
export function getHeroInfoByName(name) {
  return http.get(`/hero?name=${name}`);
}
export function createHero(data) {
  return http.post('/hero', data);
}
export function updateHeroBasicInfo(data) {
  return http.post('/hero/basic', data);
}
export function updateHeroEquips(data) {
  return http.post('/hero/equips', data);
}
export function updateHeroDropStages(data) {
  return http.post('/hero/stages', data);
}
export function updateHeroSkills(data) {
  return http.post('/hero/skills', data);
}
export function searchEquips(params) {
  const {
    keyword,
    type,
    rank,
    page = 1,
    size = 10
  } = params;
  const qsParams = {
    keyword,
    type,
    rank,
    page,
    size
  };
  const qsString = qs.stringify(qsParams);
  return http.get(`/item/search?${qsString}`);
}
export function getSummonHeroes() {
  return http.get('/hero/summon');
}
export function getStages() {
  return http.get('/stage');
}
export function deleteOpenServerNotice(id) {
  return http.delete(`/open_server_notice/${id}`);
}
export function getCodes() {
  return http.get('/codes');
}
export function createCode(data) {
  return http.post('/codes', data);
}
export function updateCode(id, data) {
  return http.post(`/codes?id=${id}`, data);
}
export function deleteCode(id) {
  return http.delete(`/codes/${id}`);
}
export function searchHeroes(params) {
  return http.get(`/hero/search?${qs.stringify(params)}`);
}
export function updateHeroVisible(id, visible) {
  return http.post(`/hero/visible?id=${id}`, {
    visible
  });
}
export function updateHeroOrder(name, order) {
  return http.post(`/hero/order?name=${name}`, {
    order
  });
}
export function getSquads(category, page = 1, size = 10) {
  return http.get(`/squad?category=${category}&page=${page}&size=${size}`);
}
export function updateSquadOrder(id, order) {
  return http.post(`/squad/order?id=${id}`, {
    order
  });
}
export function getOssSign() {
  return http.get('/oss/sign');
}
export function getEquipNameList() {
  return http.get('/item/equip-list');
}
export function getEquipInfoById(id) {
  return http.get(`/item/${id}`);
}
export function createOrUpdateEquip(data) {
  return http.post('/item', data);
}
export function getHeroNameList() {
  return http.get('/hero/name-list');
}
export function batchUpdateSummon(names, round) {
  return http.post('/hero/summon', {
    names,
    round
  });
}