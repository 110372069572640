import "@arco-design/web-react/es/Message/style";
import _Message from "@arco-design/web-react/es/Message";
import axios from 'axios';
// const baseURL =
//   process.env.NODE_ENV === 'development'
//     ? 'http://localhost:3003/api'
//     : 'https://d-wechat-be.lilith.com/api';
const baseURL = 'https://xbbcamp-be.deyu13.com/api'; // const baseURL = 'https://d-wechat-be.lilith.com/api';

class Http {
  constructor() {
    this._axios = axios.create({
      baseURL
    });
    this.initRequestInterceptors();
    this.initResponseInterceptors();
  }

  static getInstance() {
    this._instance || (this._instance = new Http());
    return this._instance;
  }

  initRequestInterceptors() {
    this._axios.interceptors.request.use(config => {
      // let { method } = config;
      // method = method?.toUpperCase();
      const token = localStorage.getItem('token');

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    }, error => {
      console.error(error.message);
      return Promise.reject(error);
    });
  }

  initResponseInterceptors() {
    this._axios.interceptors.response.use(response => {
      if (response.headers['content-type'].includes('application/json')) {
        return 'data' in response.data ? response.data.data : response.data;
      } else {
        return response;
      }
    }, async error => {
      if (!error.response || error.response.status === 503) {
        console.log('error: ', error);

        _Message.error('获取数据失败');
      } else if (error.response.data) {
        console.log('error.response.data: ', error.response.data);
        let {
          code
        } = error.response.data;
        const {
          msg
        } = error.response.data;

        if (!code) {
          const {
            statusCode
          } = error.response.data;
          code = statusCode;
        }

        if (code === 401 || code === 401004) {
          // 登录过期或无效token
          localStorage.removeItem('token');
          localStorage.removeItem('userStatus');

          if (window.location.pathname.replace(/\//g, '') !== 'login') {
            window.location.pathname = '/login';
          }
        }

        if (msg) {
          _Message.error(msg);
        } else {
          _Message.error(error.response.data?.message || error.message);
        }
      } else {
        _Message.error('网络错误');
      }
    });
  }

  request(config) {
    return new Promise((resolve, reject) => {
      this._axios.request(config).then(res => {
        resolve(res);
      }).catch(err => {
        console.error(err);
        reject(err);
      });
    });
  }

  get(url, data, config) {
    return this.request({ ...config,
      url,
      method: 'get',
      data
    });
  }

  post(url, data, config) {
    return this.request({ ...config,
      url,
      method: 'post',
      data
    });
  }

  patch(url, data, config) {
    return this.request({ ...config,
      url,
      method: 'patch',
      data
    });
  }

  delete(url, data, config) {
    return this.request({
      url,
      ...config,
      method: 'delete',
      data
    });
  }

}

export default Http.getInstance();